import React, { useRef } from "react";
import { graphql, useStaticQuery, Link } from 'gatsby'
import NewsCard from "components/NewsCard"
import Heading from 'components/Heading'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";


import { newsSection, newsCards, newsSectionHeading, sliderBtn, sliderBtns } from "./newsSection.module.css" 

import rightSliderArrow from "images/right-slider-arrow.png"
import leftSliderArrow from "images/left-slider-arrow.png"

function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button 
            className={sliderBtn + ' ' + className}
            onClick={onClick}
            style={{ ...style}}>
            
            <img src={rightSliderArrow} alt="Right slider arrow"/>
        </button>
    );
  }
  
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button 
            className={sliderBtn + ' ' + className}
            onClick={onClick}
            style={{ ...style}}>
            
            <img src={leftSliderArrow} alt="Left slider arrow"/>
        </button>

    );
}

function NewsSection() {
    const data = useStaticQuery(graphql`
    query NewsSectionQuery {
        allShopifyArticle {
            edges {
                node {
                    id
                    url
                    title
                    excerpt
                    image {
                        src
                        altText
                    }
                }
            }
        }
        shopifyBlog {
            url
        }
    }`)

    const slide = useRef()

    const next = () => {
        slide.current.slickNext()
    }

    const prev = () => {
        slide.current.slickPrev()
    }

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                  slidesToShow: 3,
                  slidesToScroll: 1
                }
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            },
          ]
      };

    const { allShopifyArticle, shopifyBlog } = data
    let news = allShopifyArticle.edges
    // get first 4 news items


    let newsJSX = news.map(({ node }) => 
        <NewsCard
            key={ node.id }
            title={node.title} 
            text={node.excerpt}
            imageURL={node.image.src}
            imageAlt={node.image.altText}
            to={'/news' + node.url.replace(shopifyBlog.url, "")}
        />
    );

    return (
        <section className={newsSection}>
            <div className={newsSectionHeading}>
                <Heading level="h3">Latest News</Heading>
                <Link to="/news"><p>View all posts</p></Link>
                <div className={sliderBtns}>
                    <PrevArrow onClick={prev}/>
                    <NextArrow onClick={next}/>
                </div>
            </div>

            <div className={newsCards}>
                <Slider ref={slide} {...settings}>
                    {newsJSX}
                </Slider>
            </div>
        </section>
    );
}

export default NewsSection