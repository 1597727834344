import React from "react";
import Heading from "components/Heading"
import Button from "components/Button"

import headerImage from "images/header-img.png";

import { hero, heroHeading, heroImage } from "./hero.module.css"

function Hero() {
    return (
        <section className={hero}>
            <div>
                <div className={heroHeading}>
                    <Heading level="h1" colour="dark-blue">Konungr. Premium Clothing</Heading>
                    <Button to="/products">View Products</Button>
                </div>
            </div>
            
            <div className={heroImage}>
                <img 
                    src={headerImage}
                    alt="Car in an icy environment"
                    loading='lazy'
                />
            </div>
        </section>
    );
};

export default Hero