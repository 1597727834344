import React from "react"
import { Link } from 'gatsby'
import Heading from 'components/Heading'

import { newsCard, newsCardText } from "./newsCard.module.css" 

function NewsCard( { imageURL, imageAlt, title, text, to } ) {
    return (
        <div className={newsCard}>
            <img src={imageURL} alt={imageAlt}/>
            <div className={newsCardText}>
                <Heading level='h4'>{title}</Heading>
                <p>{text}</p>
                <Link to={to}>Read more</Link>
            </div>
        </div>
    )
}

export default NewsCard