import React from 'react'
import Button from 'components/Button'
import Heading from 'components/Heading'

import { elementsSection, bgElementsImage, elementsBody, elementsHeading } from './elements.module.css'

import elementsImg from 'images/elements.png'

function Elements() {
    return (
        <section className={elementsSection}>
            <img className={bgElementsImage} src={elementsImg} alt="Man in sleeping bag preparing for elements"/>

            <div className={elementsBody}>
                <div>
                    <div className={elementsHeading}>
                        <Heading color="white" level="h3">Prepared for the elements.</Heading>
                        <p>All of our garments are tried and tested and ready for anything you can throw at it.</p>
                        <Button to="/about-us" textColor="white">Read more</Button>
                    </div>
                </div>
                <div></div>
            </div>
            
        </section>
    )
}

export default Elements