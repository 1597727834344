import * as React from "react"
import Header from "components/Header"
import Footer from "components/Footer"
import Hero from "components/Hero"
import NewsSection from "components/NewsSection"
import ProductSlider from "components/ProductSlider"
import Elements from "components/Elements"
import Responsibility from "components/Responsibility"

const IndexPage = () => {
  return (
    <main>
      <Header />
      <Hero />
      <ProductSlider />
      <Responsibility />
      <Elements />
      <NewsSection />
      <Footer />
    </main>
  )
}

export default IndexPage
