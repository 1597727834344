import React from 'react'
import Button from 'components/Button'
import Heading from 'components/Heading'

import { responsibilitySection, responsibilityHeading, responsibilityImg } from './responsibility.module.css'
import passionateImg from 'images/passionate-responsibility.png'

function Responsibility() {
    return (
        <section className={ responsibilitySection }>
            <div>
                <div className={responsibilityHeading}>
                    <Heading level="h3">Passionate about responsibility</Heading>
                    <p>Every day we borrow resources from the planet. So, we've joined forces with ecology in an effort to help turn the tide on climate change.</p>
                    <Button to="/about-us">Read more</Button>
                </div>
            </div>
            
            <div className={responsibilityImg}>
                <img src={passionateImg} alt="Boat in a lake surrounded by trees"/>
            </div>
        </section>
    )
}

export default Responsibility