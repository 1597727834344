import React, { useEffect, useRef, useState } from "react"
import { graphql, useStaticQuery } from 'gatsby'
import { useMediaQuery } from "react-responsive"
import ProductCard from "components/ProductCard"
import Heading from 'components/Heading'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

import { 
    productSlider, 
    productCards, 
    productSliderHeading,
    productSliderHeadingText,
    productSliderHeadingButtons,
    productCardsContainer,
    sliderBtn
} from "./product-slider.module.css"

import rightSliderArrow from "images/right-slider-arrow.png"
import leftSliderArrow from "images/left-slider-arrow.png"


function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button 
            className={sliderBtn + ' ' + className}
            onClick={onClick}
            style={{ ...style}}>
            
            <img src={rightSliderArrow} alt="Right slider arrow"/>
        </button>
    );
  }
  
  function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <button 
            className={sliderBtn + ' ' + className}
            onClick={onClick}
            style={{ ...style}}>
            
            <img src={leftSliderArrow} alt="Left slider arrow"/>
        </button>

    );
  }
  

function ProductSlider() {
    const slider = useRef()

    const next = () => {
        slider.current.slickNext()
    }

    const prev = () => {
        slider.current.slickPrev()
    }

    const data = useStaticQuery(graphql`
    query ProductSliderQuery {
        allShopifyProduct( limit: 12 ) {
            edges {
                node {
                    id
                    options {
                        values
                        name
                    }
                    priceRange {
                        maxVariantPrice {
                            amount
                        }
                        minVariantPrice {
                            amount
                        }
                    }
                    title
                    description
                    handle
                    images {
                        localFile {
                            url
                        }
                    }
                    variants {
                        price
                    }
                }
            }
        }
    }
    `);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 600,
              settings: {
                slidesToScroll: 1
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToScroll: 1
              }
            }
          ]
      };

    const { allShopifyProduct } = data
    let products = allShopifyProduct.edges
    let productsJSX = products.map(({ node }) => {
        // get colors
        let numColors = 1
        node.options.forEach(option => {
            if (option.name === "Colour") {
                numColors = option.values.length
            }
            
        });
        return (
            <div className="productCardContainer" key={node.id}>
                <ProductCard product={ node } colors={numColors} hoverEffect={ true }
                />
            </div>
        );
    });

    return (
        <section className={productSlider}>
            <div className={productSliderHeading}>
                <div className={productSliderHeadingText}>
                    <p>New In</p>
                    <Heading level="h3">A/W 2020</Heading>
                </div>
                    <div className={productSliderHeadingButtons}>
                        <PrevArrow onClick={prev}/>
                        <NextArrow onClick={next}/>
                    </div>
            </div>

            <div className={productCardsContainer}>
                <div className={productCards}>
                    <Slider ref={slider} {...settings}>
                        {productsJSX}
                    </Slider>
                </div>
            </div>
        </section>
    );
}

export default ProductSlider